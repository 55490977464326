import styled, {css} from 'styled-components'

export const StyledHomepageMobileFlightSearchWrapper = styled.div`
  @media(min-width: 1200px) {
    display: none;
  }
`

export const StyledArticleOverviewWrapper = styled.section`
  width: 100%;

  p {
    color: #3C3C3B;
    font-family: Raleway;
    max-width: 100%;
    text-align: left;
    font-size: 21px;
    letter-spacing: 0;
    line-height: 27px;
    font-weight: 400;

    &:first-of-type {
      /* TODO: test and remove */
      /* margin-top: 40px; */
    }
  }
`

export const StyledInsetIcons = styled.div`
  display: flex; 
  align-items: center;
  margin: 15px 0px;

  p {
    margin-bottom: 0 !important;
    font-size: 14px !important;
    margin-left: 15px !important;
  }

  img {
    width: 30px; 
    height: 100%;
  }

`;

export const StyledFeaturedInsetImgWrapper = styled.div`
  .richtext{
    p { font-size: 14px !important; }
  }
`;

export const StyledDestinationTextWrapper = styled.section`
  width: 100%;

  h2 {
    font-size: 24px;
    color: #3C3C3B;
    font-family: Raleway;
  }

  p {
    color: #636463;
    font-family: "Open Sans";
    font-size: 21px;
    letter-spacing: 0;
    line-height: 27px;
    width: 100%;
    margin-top: 60px;

    @media(min-width:768px) {
      width: 80%;
    }

    @media(min-width:1024px) {
      width: 60%;
    }
  }

`

export const StyledAllCountriesButtonMobile = styled.div`
  width: 100%;
  display: flex; 
  justify-content: center; 
  margin-top: 0px;

  @media(min-width: 640px) {
    display: none;
  }
`

export const StyledProductPageParagraphWrapper = styled.section`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;

  @media(min-width:1024px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  p {
    color: #3C3C3B;
    font-family: Raleway;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
  }
`

export const StyledInspirationalSlicesWrapper = styled.section`
  
  @media(min-width: 1024px) {
    padding-bottom: 100px;
  }
`

export const StyledProductOverviewWrapper = styled.section`
  width: 100%;
  margin-top: 40px;

  @media(min-width:1024px) {
    margin-top: 40px;
  } 

  p {
    color: #3C3C3B;
    font-family: Raleway;
    max-width: 100%;
    text-align: left;
    font-size: 21px;
    letter-spacing: 0;
    line-height: 33px;
    font-weight: 400;

    @media(min-width:1024px) {
      max-width: 60%;
      font-size: 28px;
    }
  }
`

export const StyledPressAndMediaOverviewWrapper = styled.div`
  margin-top: 30px;
  
  p {
    color: #636463; 
    font-family: "Open Sans";
    max-width: 100%;
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    margin-bottom: 8px;
    margin-bottom: 34px;
  }
`;

export const StyledMediaArticlesWrapper = styled.div`
  width: 100%;
  
  p { 
    margin-bottom: 15px; 
  }

  @media(min-width:1024px) {
    /* max-width: 60%; */
  }

  button {
    letter-spacing: 0.93px;
    padding-left: 0px;
    margin: 40px 0px;
  }
`;

export const StyledProductSlicesWrapper = styled.div`
  position: relative;
  padding-bottom: 20px;

  .product-container-top-half-overlay {
    content: "";
    position: absolute;
    height: 50%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(212,214,222,0) 0%, #D4D6DE 100%);
    z-index: -1;
  }

  .product-container-bottom-half-overlay {
    content: "";
    position: absolute;
    height: 50%;
    width: 100%;
    top: 50%;
    left: 0;
    background: linear-gradient(0, rgba(212,214,222,0) 0%, #D4D6DE 100%);
    z-index: -1;
    
  }

  @media(min-width: 640px) {
    padding-bottom: 0;
  }
  
`

export const SectionWrap = styled.section`
 ${props =>
    props.left &&
    css`
     .oembedStyle{
        text-align:left;
      }
  `};
`

export const SpecsCol = styled.tr`
  width: 100%;
  border-bottom: solid thin #C4C0C6;
  padding:0.5rem 0 0.5rem 0;

  :last-child{
    border-bottom:none;
  }

  @media(min-width:950px){
    width:50%;
    :nth-last-child{
      border-bottom:none;
    }
  }
`
export const SpecName = styled.td`
  display:inline-block;
  border:0;
  font-weight: 900;
  min-width:140px;
    @media(min-width:950px){
      min-width:110px;
    }
`
export const SpecValue = styled.td`
  display:inline;
  border:0;
`

export const AuthorFooter = styled.footer`
  display: flex;
  padding: 0.5em;
  border: 1px solid grey;
  margin-bottom: 1em;
`

export const ButtonStyle = styled.a`
  width: 80%;
  padding: 12px 40px !important;
  margin-right: 0;
  background-image: linear-gradient(260deg, #e30613, #5b2f49);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px !important;
  color: white;
  outline: none;
  border: 1px solid transparent;
  line-height: inherit;

  :hover {
    background: linear-gradient(260deg, #5b2f49, #e30613);
    color: white;
    text-decoration: none;
  }

 @media (min-width: 768px) {
  margin-right: 1em;
  width: auto;
 }
`

export const ActionButton = styled(ButtonStyle)`
 margin-right: 0;
 padding: 10px 20px !important;
 width: 90%;

 @media (min-width: 768px) {
    width: 90%;
}
`

export const SocialWrapper = styled.section`
display:flex;
height:25px;
pointer-events:none;
*{
  margin-right:1em;
}
`

export const DateAuth = styled.p`
  height:25px;
  display:block;
  margin-bottom:unset;
`

export const DestinationSection = styled.div`
  display:inline-flex;
  width:100%;
  justify-content:space-evenly;
  padding:0.5rem;
  text-align:center;
  flex-wrap:wrap;

  @media(min-width:950px){
    width:100%;
  }
  `

export const DestinationDiv = styled.div`
  width:100%;
  padding-bottom:1.5rem;
  text-align:center;
  margin:0.5rem;

  @media(min-width:950px){
    width:300px;
    text-align:left;
  }
  `

export const DestinationSummary = styled.div`
  padding-top:0.5rem;
  text-align:left;
`

export const StyledPhoneIcon = styled.span`
  &&&&&&& {
    display: flex;
    height: 26px;
    width: 26px;
    line-height: 26px;
    margin-right: 5px;
    justify-content: center;
    align-items: center;
    border: 2px solid #e30613;
    border-radius: 100%;
    background: ${props => `url(${props.phoneIcon}) no-repeat bottom center`} no-repeat 50% 50%;
    background-size: 50% 50%;
    background-color: #fff;

    #card-phone {
      height: 50% !important;
      width: 50% !important;
    }
  }
`

export const AnchorLink = styled.a`
  :hover {
    text-decoration:underline;
  }
`

export const CampaignStyle = styled.section`
  margin-bottom: 1.3em;
  img {
    display:block;
    padding-bottom: 0;
    padding-right: 0;
    margin-bottom: 1em;
    width: 130px;
    height: 60px;
  }
  
  ul {
    list-style-type: none;
    overflow: hidden;
    padding-left:0;
  }

  @media(min-width:600px){
    img {
      float:left;
      margin-bottom: 0;
      margin-right: 1em;
    }
  }
`

export const StyledWhyCardsSection = styled.section`
  position:relative;  

  .whysection-background {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }
 `